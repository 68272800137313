@import '../../content/scss/variables.scss';
@import '../../content/scss/mixins.scss';

.not-found-page {
  min-height: 100vh;
  h1 {
    font-family: 'OpenSans Regular';
  }
  a {
    color: $subtitle-color;
    &:hover {
      color: $orange;
    }
  }
  .plants-left-bg-image {
    bottom: 0;
    left: 5%;
    width: 295px;
    height: 198px;
    z-index: -1;
  }
  .plants-right-bg-image {
    bottom: 0;
    right: 5%;
    width: 355px;
    height: 305px;
    z-index: -1;
  }
}
/*RESPONSIVE*/
@include mobile-tablet() {
  .not-found-page {
    .plants-left-bg-image,
    .plants-right-bg-image {
      display: none;
    }
  }
}

@include desktop() {
  .not-found-page {
    .plants-left-bg-image {
      bottom: 0;
      left: 0;
      width: 195px;
      height: 98px;
    }
    .plants-right-bg-image {
      bottom: 0;
      right: 0;
      width: 255px;
      height: 155px;
    }
  }
}
