@mixin mobile() {
  @media screen and (max-width: 575.98px) {
    @content;
  }
}

@mixin mobile-tablet() {
  @media screen and (max-width: 767.98px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin specific-tablet() {
  @media screen and (min-width: 576px) and (max-width: 767.98px) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: 992px) and (max-width: 1198.98px) {
    @content;
  }
}

@mixin specific-desktop() {
  @media screen and (min-width: 1200px) and (max-width: 1420px) {
    @content;
  }
}

@mixin specific-large-desktop() {
  @media screen and (min-width: 1200px) and (max-width: 1900px) {
    @content;
  }
}

@mixin large-desktop() {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
