@import './content/scss/components/buttons.scss';
@import './content/scss/typography.scss';
@import './content/scss/mixins.scss';

@font-face {
  font-family: OpenSans Light;
  src: url('./content/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: OpenSans Regular;
  src: url('./content/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: OpenSans SemiBold;
  src: url('./content/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: OpenSans Bold;
  src: url('./content//fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Caveat Regular;
  src: url('./content/fonts/Caveat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Caveat Medium;
  src: url('./content/fonts/Caveat-Medium.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: Caveat Bold;
  src: url('./content/fonts/Caveat-Bold.ttf') format('truetype');
  font-weight: 700;
}

.large-container {
  max-width: 1510px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.medium-container {
  max-width: 745px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

body {
  margin: 0 auto;
  font-family: OpenSans Regular, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*RESPONSIVE*/
@include mobile-tablet() {
}
@include tablet() {
  .large-container {
    max-width: 720px;
  }
  .medium-container {
    max-width: 720px;
  }
}

@include desktop() {
  .large-container {
    max-width: 960px;
  }

  .medium-container {
    max-width: 745px;
  }
}
