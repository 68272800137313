@import '../../content/scss/variables.scss';
@import '../../content/scss/mixins.scss';

.homepage-container {
  min-height: 100vh;
  background: url('../../images/header-bg-img.svg') no-repeat 100% 0%;

  header {
    .header-top {
      h1 {
        font-family: OpenSans SemiBold;
      }
      p {
        color: $dark-grey;
      }
      button {
        width: 140px;
      }
    }
    .header-bottom {
      h1,
      span {
        font-family: Caveat Regular;
        margin-top: 211px;
      }
      a {
        width: 144px;
        font-family: 'OpenSans Regular';
        font-size: 16px;
      }
      .rectangle-header-position {
        bottom: 10%;
        left: 45%;
      }
    }
  }
  section#about-us {
    .triangle-about-us-position {
      right: 2%;
      bottom: 30%;
    }
    .rectangle-about-us-position {
      left: 21%;
      bottom: 15%;
    }
  }

  section#set-and-forget {
    h2,
    span {
      font-family: Caveat Regular;
    }
    .icon-circle {
      border-radius: 50%;
      min-height: 65px;
      min-width: 65px;
      background-color: $light-orange;
      svg {
        height: 32px;
        width: 32px;
      }
    }
    p.set-and-forget-subtitle {
      font-family: OpenSans SemiBold;
    }
  }
  .rectangle-set-and-forget-position {
    right: 15%;
    top: 20%;
  }
  .dashed-line-position {
    left: 0;
    bottom: -130px;
  }
}
section#dollar-cost-average,
section#buy-more,
section#faq,
section#sign-up {
  h2,
  span {
    font-family: Caveat Regular;
  }
}

section#dollar-cost-average {
  span.invest-style {
    font-family: 'OpenSans Bold';
    background-color: $light-orange;
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }
  .circle-position {
    top: 5%;
    left: 60%;
  }
  .rectangle-dollar-cost-position {
    right: 5%;
    top: 70%;
  }
}
section#faq {
  .triangle-faq-position {
    left: 35%;
    bottom: 0;
  }
  .circle-faq-position {
    right: 5%;
    top: 25%;
  }
}

section#buy-more {
  .circle-buy-more-position {
    bottom: 0;
    right: 19%;
  }
  .triangle-buy-more-position {
    left: 50%;
    top: 0;
  }
}

section#security-is-serious {
  background: url('../../images/orange-background.png') no-repeat center center;
  background-size: cover;
  min-height: 570px;
  p {
    font-family: 'OpenSans Light';
  }
}

section#sign-up {
  background: url('../../images/light-orange-background.png') no-repeat center
    center;
  background-size: cover;
  min-height: 453px;
  h2,
  p {
    color: $dark-grey;
  }

  form {
    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 46px;
      &:focus {
        box-shadow: none !important;
      }
    }
    button {
      width: 144px !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .message-container {
      bottom: -20px;
    }
  }
  .text-danger {
    font-size: 12px;
  }
}

footer {
  h1 {
    font-family: OpenSans Bold;
  }
  p {
    color: $lighter-grey;
  }

  a {
    line-height: 30px;
    color: $dark-grey;
    &:hover {
      color: $orange;
    }
  }
}

/*RESPONSIVE*/

@include large-desktop() {
  header {
    min-height: 900px;
  }
}

@include desktop() {
  .homepage-container {
    background-size: contain;
    header {
      .header-bottom {
        h1,
        span {
          margin-top: 20px;
        }
      }
    }
    section#set-and-forget {
      .icon-circle {
        min-height: 45px;
        min-width: 45px;
        svg {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
  section#security-is-serious {
    h2 {
      font-size: 35px;
    }
  }
}

@include tablet() {
  .homepage-container {
    background: none;
    header {
      background-color: $bg-ligth-orange;
      .header-bottom {
        h1,
        span {
          margin-top: 48px;
        }
      }
      .header-bottom-img-position {
        left: 0;
      }
    }
    section#set-and-forget {
      .light-orange-shape-position {
        top: 0;
        right: 0;
        z-index: -1;
      }
    }
    section#dollar-cost-average {
      background-color: $bg-ligth-orange;
      span.invest-style {
        background-color: $orange;
        color: white;
      }
    }
    section#buy-more {
      p {
        margin-bottom: 150px;
      }
      .security-serious-top-position {
        bottom: -1px;
        left: 0;
      }
    }
    section#security-is-serious {
      background: none;
      background-color: $orange;
      h2 {
        font-size: 30px;
      }
    }
    section#sign-up {
      min-height: 400px;
    }
    footer {
      .rights-style {
        border-top: 1px solid $grey;
      }
    }
  }
}

@include mobile-tablet() {
  .homepage-container {
    background: none;
    header {
      background-color: $bg-ligth-orange;
      .header-top {
        border-bottom: 1px solid $grey;
        button {
          height: 36px;
        }
      }
      .header-bottom {
        h1,
        span {
          margin-top: 48px;
        }
      }
      .header-bottom-img-position {
        left: 0;
      }
    }
    section#about-us {
      .img-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
    section#set-and-forget {
      .light-orange-shape-position {
        top: 0;
        right: 0;
        z-index: -1;
      }
    }
    section#dollar-cost-average {
      background-color: $bg-ligth-orange;
      span.invest-style {
        background-color: $orange;
        color: white;
      }
    }
    section#buy-more {
      p {
        margin-bottom: 100px;
      }
      .security-serious-top-position {
        bottom: -1px;
        left: 0;
      }
    }
    section#security-is-serious {
      background: none;
      background-color: $orange;
      h2 {
        font-size: 30px;
      }
    }
    section#sign-up {
      min-height: 350px;
    }
    footer {
      .rights-style {
        border-top: 1px solid $grey;
      }
    }
  }
}
