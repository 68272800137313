/*colors*/
$orange: #ff7000;
$dark-grey: #2c2c4d;
$light-grey: #a3aac2;
$black-grey: #323130;
$medium-grey: #8189a8;
$bg-grey: #e5e5e5;
$light-green: rgba(111, 207, 151, 0.2);
$medium-green: #27ae60;
$light-orange: #faedd9;
$lighter-grey: #676f97;
$grey: #bfc4d8;
$black-grey: rgba(0, 0, 0, 0.38);
$subtitle-color: #333333;
$light-purple: #e0e3ec;
$navy: #2c2c4d;
$bg-ligth-orange: #fff3dd;
$light-grey-indicator: #eff0f5;
$light-blue: #f7f8fa;
$red: #eb5757;
$green: #27ae60;
$turquoise: #28d2c6;
$inactive-grey: #e6e6e6;
