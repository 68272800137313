@import '../variables.scss';
@import '../mixins.scss';

.btn-primary {
  color: white !important;
  border-color: $orange !important;
  background-color: $orange !important;
  height: 46px;
}
.btn-outline-grey {
  color: $orange !important;
  border-color: $light-grey !important;
  background-color: transparent !important;
  height: 46px;
}
.btn:focus {
  border-color: $orange !important;
  outline: 0;
  box-shadow: none !important;
}

.account-button {
  min-width: 177px;
}
/*RESPONSIVE*/
@include mobile-tablet() {
  .account-button {
    min-width: unset;
  }
}
