@import '../../content/scss/variables.scss';
@import '../../content/scss/mixins.scss';

.faq-container {
  border-bottom: 1px solid $grey;
  #question-collapse {
    p {
      color: $black-grey;
    }
  }
  .circle-faq-position {
    top: 25%;
    right: 8%;
  }
  .triangle-faq-position {
    left: 35%;
    bottom: 5%;
  }
}
/*RESPONSIVE*/
@include mobile-tablet() {
  .faq-container {
    min-height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p.fs-6 {
      font-size: 17px;
    }
  }
}
