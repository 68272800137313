@import '../scss/variables.scss';
@import '../scss/mixins.scss';
.fs-1 {
  font-size: 65px;
}

.fs-2 {
  font-size: 50px;
}

.fs-3 {
  font-size: 30px;
}

.fs-4 {
  font-size: 24px;
}

.fs-5 {
  font-size: 20px;
}

.fs-6 {
  font-size: 18px;
}

.fs-7 {
  font-size: 16px;
}
.fs-8 {
  font-size: 14px;
}

.text-orange {
  color: $orange;
}

.text-light-grey {
  color: $black-grey;
}

.text-dark-grey {
  color: $dark-grey !important;
}

/*RESPONSIVE*/
@include mobile-tablet() {
  .fs-1 {
    font-size: 35px;
  }

  .fs-2 {
    font-size: 35px;
  }

  .fs-3 {
    font-size: 20px;
  }
}
@include tablet() {
  .fs-1 {
    font-size: 35px;
  }

  .fs-2 {
    font-size: 35px;
  }

  .fs-3 {
    font-size: 20px;
  }
}

@include desktop() {
  .fs-1 {
    font-size: 55px;
  }

  .fs-2 {
    font-size: 40px;
  }

  .fs-3 {
    font-size: 25px;
  }

  .fs-5 {
    font-size: 17px;
  }

  .fs-6 {
    font-size: 15px;
  }

  .fs-8 {
    font-size: 13px;
  }
}
